import axios from "axios";

export default function requestUpdateSingle(endpoint){

    const { pagename, id, data, parentid, placeorder } = endpoint;

    let lang = localStorage.getItem('i18nextLng');

    const config = {
        params: {
            'parentid': parentid
        },        
        headers: {
            "x-agent": localStorage.getItem('agent-customer'),
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    const blob = data;

    // let blob;
    // if(placeorder){
    //     blob = {
    //         ...data,
    //         placeorder: placeorder ? 'true' : 'false'
    //     }
    // } else {
    //     blob = data;
    // }

    return axios.put(process.env.REACT_APP_API_URL+"/"+lang+"/"+pagename+"/"+id, JSON.stringify(blob), config);
}