import { createSlice } from '@reduxjs/toolkit';

export const singleSlice = createSlice({
  name: 'single',
  initialState: {
    isError: false,
    isErrorTxt: false,
    isSuccess: false,
    isPreviewSuccess: false,
    isSuccessBut: false,
    pagename: null,
    title: null,
    multilang: null,
    duplicate: null,
    apicache: null,
    deletes: null,
    create: null,
    preview: null,
    visualblock: null,
    translate: null,
    placeorder: false,
    id: null,
    url: null,
    params: null,
    post: [],
    activeColumns: false,
    information: false
  },
  reducers: {
    createSingle() {},
    updateSingle() {},
    copyColumns() {},
    setParams(state, action) {
      const { pagename, id } = action.payload;
      state.pagename = pagename;
      state.id = id;
    },
    getSingle() {},    
    setPost(state, action) {
      const post = action.payload;
      state.post = post;
    },
    purgePost(state) {
      state.post = [];
    },    
    setSingle(state, action) {      
      const { post } = action.payload;
      state.post = post.items;
      state.id = post.id;
      state.url = post.url;
      state.title = post.title;
      state.pagename = post.pagename;
      state.multilang = post.multilang;
      state.duplicate = post.duplicate;
      state.information = post.info;
      state.template = post.template;
      state.apicache = post.apicache;
      state.delete = post.deletes;
      state.create = post.create;
      state.translate = post.translate;
      state.visualblock = post.visualblock;
      state.copy = post.copy;
      state.url = post.url;
      state.preview = post.preview;
      state.ispreview = post.ispreview;
      state.isSuccess = false;
      state.isSuccessBut = false;
      state.isError = false;
      state.isErrorTxt = false;
      // return state;
    },
    updateCurrentSingle(state, action) {
      const { items, info, ispreview, preview, url, statuscode } = action.payload;
      state.isSuccessBut = statuscode == 204 ? true : false;
      state.isSuccess = statuscode == 204 ? false : true;
      state.isError = false;
      state.isErrorTxt = false;
      state.post = items;
      state.information = info;
      state.url = url;
      state.preview = preview;
      state.ispreview = ispreview;
      return state;
    },
    sendParams(state, action) {
      const { params } = action.payload;
      state.param = params;
    },
    setIs(state) {
      state.isSuccess = false;
      state.isSuccessBut = false;
      state.isError = false;
      state.isErrorTxt = false;
    },    
    setError(state, action) {
      const txt = action.payload;
      state.isSuccess = false;
      state.isSuccessBut = false;
      state.isError = true;
      state.isErrorTxt = txt;
      return state;
    },
    purgeSingleState(state){
      state.isSuccess = false;
      state.isSuccessBut = false;
      state.isError = false;
      state.isErrorTxt = false;
      state.id = null;
      state.pagename = null;
      state.multilang = null;
      state.duplicate = null;
      state.information = null;
      state.copy = null;
      state.post = [];      
      return state;
    },
    tableColumns(state, action){
      const value = action.payload;
      state.activeColumns = value;
    },
    isPlaceOrder(state, action){
      const value = action.payload;
      state.placeorder = value;
    }
  }
})

export const { 
  getSingle, 
  setSingle, 
  createSingle, 
  updateSingle, 
  updateCurrentSingle, 
  setError, 
  purgeSingleState, 
  setParams, 
  sendParams, 
  setIs, 
  tableColumns,
  copyColumns,
  setPost,
  purgePost,
  isPlaceOrder
} = singleSlice.actions;

export default singleSlice.reducer;
